#onas-style {
    background-color: #1A1A1A;
    height: 100vh;
    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 10vw;
    .left {
        position: relative;
        width: 30vw;
        float: left;
        margin-top: 30vh;
        
        h1 {
            font-size: 4vw;
            font-weight: bold;
            color: #FFFFFF;
            
            top: 20vh;
        }
        p {
            font-size: 20px;
            color: #FFFFFF;
            width: 30vw;
        }
    }
    .right {
        
        float: right;
        position: relative;
        margin-top: 30vh;
        .profilepic {
            width: 25vw;
            height: auto;
            min-width: 200px;
            border-radius: 10px;
        }
        h2 {
            color: #FFFFFF;
            font-weight: 500;
            font-size: 20px;
        }
    }
    
}

@media screen and (max-width: 900px) {
    #onas-style {
        position: relative;
        flex-direction: column;
        justify-content: space-evenly;
        .left {
            float: none;
            width: 80vw;
            margin-top: 0vh;
            h1 {
                font-size: 22px;
            }
            p {
                width: 80vw;
                font-size: 16px;
            }
        }
        .right {
            float: none;
            position: relative;
            width: 100%;
            margin-top: 0vh;
            .profilepic {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
            h2 {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
}
    