#navbar {
    width: fit-content;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1000;
    .center-btn {
        margin: 0 3vw;
    }
    .left-btns {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 300px;
        
    }
    .right-btns {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 300px;
        
    }
    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    .link {
        font-weight: 700;
        font-size: 18px;

        &:hover {
            opacity: 70%;
        }
    }

    .white {
        font-weight: 500;
        color: #FFFFFF;
    }

    .black {
        color: #000000;
    }

    .dropdown {
        button {
            margin-top: 25px;

            .arrow {
                position: relative;
                top: 2px;
                transition: 0.1s all;

            }

            .up {
                transform: rotate(180deg);
            }

            .white {
                color: #FFFFFF;
            }

            .black {
                color: #000000;
            }

            &:hover {
                .arrow {
                    transition: 0.1s all;
                    top: 4px;
                }

            }

        }

        .btns {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            button {
                margin-top: 10px;
            }
        }
    }
}

#mobile-nav {
    display: none;
    z-index: 1000;
    position: fixed;
    width: 100vw;
    top: 20px;
    white-space: nowrap;

    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    .burger {
        height: auto;
        width: 40px;
    }

    .link {
        font-weight: 700;
        font-size: 40px;

        &:hover {
            opacity: 70%;
        }
    }


    .white {
        font-weight: 500;
        color: #FFFFFF;
    }

    .black {
        color: #000000;
    }

    .nav-mobile {
        position: absolute;
        top: 90px;
        left: 0;
        width: 100%;
        height: 100vh;
        padding-top: 10vh;
    }
    .nav-mobile-bg-w {
        background-color: #FFFFFF;
    }
    .nav-mobile-bg-b {
        background-color: #000000;
    }

    .dropdown {

        margin-top: -30px;

        button {
            margin-top: 25px;

            .arrow {
                position: relative;
                top: 6px;


            }

            .up {
                transform: rotate(180deg);
            }

            .white {
                color: #FFFFFF;
            }

            .black {
                color: #000000;
            }

            &:hover {
                .arrow {
                    transition: 0.1s all;
                    top: 8px;
                }
            }
        }

        .btns {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    #navbar {
        display: none;
    }

    #mobile-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            &:nth-child(1) {
                margin-left: 10vw;
            }

            &:nth-child(2) {
                margin-right: 10vw;
            }
        }

        .nav-mobile {
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 5vh;
            align-items: center;

            button {
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
            }
        }
    }
}