#senzorji-pres-style {
    background-color: #B4264A;
    height: 100vh;
    padding: 0 10vw;
    position: relative;

    .sen-pres-style {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            color: #FFFFFF;
            margin: 0;
            padding: 0;
            font-size: 110px;
            font-weight: bold;
            margin-bottom: 30px;
            margin-top: 30vh;
            white-space: nowrap;
        }

        .razisci {
            background-color: #FFFFFF;
            border: none;
            border-radius: 50px;
            width: 200px;
            height: 65px;
            color: #B4264A;
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
            transition: 0.3s all;
            text-decoration: none;

            &:hover {
                transition: 0.3s all;
                top: 49vh;
            }

        }
    }

}

#senzorji-style {
    background-color: #B4264A;
    height: 100vh;

    .content {
        color: #FFFFFF;
        position: relative;

        h1 {
            margin: 0;
            padding: 0;
            font-size: 110px;
            font-weight: bold;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 30vh;
            white-space: nowrap;
        }

        h2 {
            font-size: 36px;
            font-weight: bold;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 50vh;
        }



        .picker {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 10vw;

            top: 60vh;

            .pres-wrap {
                position: relative;
                cursor: pointer;

                .krog {
                    width: 80px;
                    height: 80px;
                    border-radius: 100%;
                    background-color: #FFFFFF;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);

                    p {
                        padding: 0;
                        margin: 0;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        color: #B4264A;
                        font-size: 30px;
                        font-weight: bold;
                    }
                }

                .title {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    white-space: nowrap;
                    top: 90px;
                }
            }
        }


    }

    #predstavitev {
        color: #FFFFFF;
        position: relative;
        padding-left: 10vw;
        padding-right: 10vw;
        height: 100vh;
        background-color: #B4264A;
        overflow: auto;

        .back {
            background-color: transparent;
            border: none;
            position: relative;
            top: 15vh;
            display: flex;
            flex-direction: row;
            height: 30px;
            cursor: pointer;
            opacity: 80%;

            p {

                color: #FFFFFF;
                font-size: 18px;
                margin-top: 3px;
                margin-left: 16px;

            }

            svg {
                transition: 0.3s all;
                width: 24px;
                height: auto;
                color: #FFFFFF;
                position: absolute;
                left: -6px;
            }

            &:hover {
                svg {
                    transition: 0.3s all;
                    left: -10px;
                }
            }
        }

        .pres-num {
            font-size: 20px;
            position: relative;
            opacity: 60%;
            top: 18vh;
        }

        .pres-name {
            font-size: 70px;
            font-weight: bold;
            position: relative;
            top: 20vh;
        }

        .extra-files {
            position: absolute;
            right: 10vw;
            top: 28vh;
            z-index: 1000000;

            button {
                cursor: pointer;
                border: none;
                border-radius: 20px;
                background-color: #FFFFFF;
                color: #B4264A;
                font-weight: bold;
                font-size: 17px;
                height: 40px;
                width: 220px;

                svg {
                    position: relative;
                    top: 3px;
                    width: 18px;
                    transition: 0.1s all;
                }

                &:hover {
                    svg {
                        transition: 0.1s all;
                        top: 5px;
                    }

                }
            }

            .btn-down {
                border-radius: 20px 20px 0px 0px;

                svg {
                    transform: rotate(180deg);
                }
            }

            .no-pad-down {
                padding-bottom: 0px !important;
            }

            .files {
                width: 180px;
                background-color: #FFFFFF;
                padding-left: 20px;
                padding-right: 20px;
                border-radius: 0px 0px 20px 20px;
                padding-bottom: 5px;

                .file {
                    margin-bottom: 10px;
                    padding-top: -10px;
                    color: #000000;

                    img {
                        width: 30px;
                        margin-top: 4px;
                        position: relative;
                        margin-right: 20px;
                    }

                    .wrapper {
                        width: 140px;
                        margin-left: 40px;
                        margin-top: -35px;

                        line-height: 12px;

                        a {
                            color: #000000;
                            text-decoration: none;
                            font-size: 11px;
                            font-weight: 600;

                            position: relative;
                        }
                    }

                }
            }
        }

        .content {
            display: flex;
            flex-grow: 1;
            flex-direction: row;
            justify-content: space-between;
            top: 22vh;

            img {
                max-width: 25vw;
                height: 28vw;
            }

            .solo-poster {
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
            }

            .solo-video {
                width: 50vw;
                height: 28vw;
                border-radius: 20px;
                border: none;
            }

            .videos {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: auto;
                margin-right: auto;

                .left {
                    width: 10vw;
                    height: 18vw;
                    border-radius: 20px 0px 0px 20px;
                    border: none;
                    opacity: 60%;
                }

                .center {
                    width: 50vw;
                    height: 28vw;
                    border-radius: 20px;
                    border: none;
                }

                .right {
                    width: 10vw;
                    height: 18vw;
                    border-radius: 0px 20px 20px 0px;
                    border: none;
                    opacity: 60%;
                }

                .left-btn {
                    width: 11vw;
                    height: 19vw;
                    left: 4vw;
                    position: absolute;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;

                    svg {
                        color: white;
                        width: 5vw;
                        height: auto;
                        transition: 0.1s all;
                    }

                    &:hover {
                        svg {
                            transition: 0.1s all;
                            margin-right: 10px;
                        }

                    }
                }

                .right-btn {
                    width: 11vw;
                    height: 19vw;
                    right: 4vw;
                    position: absolute;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    z-index: 100;

                    svg {
                        color: white;
                        width: 5vw;
                        height: auto;
                        transition: 0.1s all;
                    }

                    &:hover {
                        svg {
                            transition: 0.1s all;
                            margin-left: 10px;
                        }

                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #senzorji-pres-style {
        .sen-pres-style {
            h1 {
                font-size: 80px;
            }
        }

    }

    #senzorji-style {
        .content {
            h1 {
                font-size: 80px;
            }

            h2 {
                font-size: 30px;
            }
        }

        #predstavitev {
            .pres-name {
                font-size: 60px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    #senzorji-pres-style {
        .sen-pres-style {
            h1 {
                font-size: 70px;
            }
        }

    }

    #senzorji-style {
        .content {
            h1 {
                font-size: 70px;
            }

            h2 {
                font-size: 26px;
            }
        }

        #predstavitev {
            .pres-name {
                font-size: 40px;
            }

            .content {
                flex-direction: column;
                align-items: center;
                gap: 5vh;
                top: 350px;
                position: relative;
                width: auto;

                img {
                    min-width: 100%;
                    height: auto;
                    margin-bottom: 30px;
                }

                .solo-video {
                    width: 80vw;
                    height: 34vh;
                }
            }

            .extra-files {

                right: auto;
                width: 80vw;

                top: 360px;

                button {
                    width: 100%;
                }

                .files {
                    width: auto;

                    .file {
                        .wrapper {
                            width: auto;
                        }
                    }
                }
            }

        }
    }
}





@media screen and (max-width: 700px) {
    #senzorji-pres-style {
        .sen-pres-style {
            h1 {
                font-size: 60px;
            }
        }

    }

    #senzorji-style {
        .content {
            h1 {
                font-size: 60px;
            }


        }
    }
}

@media screen and (max-width: 600px) {
    #senzorji-pres-style {
        .sen-pres-style {
            height: 100vh;
            align-items: initial;

            h1 {
                width: 80vw;
                white-space: initial;
                font-size: 60px;
            }

            .razisci {
                min-height: 55px;
                max-height: 55px;
                width: 160px;
                font-size: 16px;
            }
        }

    }

    #senzorji-style {
        .content {
            h1 {
                text-align: center;
                width: 80vw;
                white-space: initial;
                font-size: 60px;
                margin-top: 20vh;
            }

            h2 {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}