#eu-projekti-style {
    background-color: #001489;
    height: 100vh;
    position: relative;
    padding: 0 15vw;

    .content {
        display: flex;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        
        .left {
            width: 60vw;
            color: #FFFFFF;
            position: relative;
            h1 {
                font-size: 4vw;
            }
            h2 {
                font-weight: 500;
                font-size: 2vw;
            }
            ul {
                width: 40vw;
                li {
                    margin-bottom: 2vw;
                    a {
                        color: #00A5EC;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .right {
            display: flex;
            flex-direction: column;
            width: 30vw;
            img {
                position: relative;
                left: 50%;
                transform: translate(-50%);
                margin-bottom: 10vh;

                

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
            }
        }
    }
    
}

@media screen and (max-width: 900px) {
    #eu-projekti-style {
        padding: 0 10vw;
        .content {
            
            flex-direction: column;
            top: 50%;
            transform: translateY(-60%);
            .left {
                width: 80vw;
                
                h1 {
                    width: fit-content;
                    font-size: 22px;
                }
                h2 {
                    font-size: 18px;
                }
                ul {
                    width: 70vw;
                    li {
                        margin-bottom: 1vw;
                        font-size: 12px;
                    }
                }
            }
            .right {
                width: 80vw;
                img {
                    width: 40vw;
                    margin-bottom: 3vh;
                    &:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}


/* @media screen and (max-height: 600px) {
    #eu-projekti-style {
        .content {
            transform: translateY(-45%);
        }
    }
} */