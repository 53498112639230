#kontakt-style {
    background-color: #284591;
    height: 100vh;
    padding: 0 10vw;
    position: relative;
    .content {
        display: flex;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        .left {
            width: 35vw;
            position: relative;
            p {
                position: absolute;
                top: 50%;
                transform: translateY(-100%);
                font-size: 5vw;
                color: #FFFFFF;
                font-weight: bold;
                margin-left: 5vw;
            }
        }
    
        .right {
            display: grid;
            grid-template-columns: 1fr 1fr;
            
            width: 60vw;
            
    
            .kontakt-item {
                margin-bottom: 5vh;
                
                .icon {
                    border-radius: 100px;
                    background-color: #FFFFFF;
                    width: 80px;
                    height: 80px;
    
                    img {
                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
    
                h1 {
                    margin-top: -60px;
                    margin-left: 100px;
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 28px;
                }
    
                ul {
                    margin-top: 50px;
    
                    li {
                        font-size: 19px;
                        color: #FFFFFFAA;
                    }
                }
                
            }
        }
    }


}

@media screen and (max-width: 900px) {
    #kontakt-style {
        .content {
            flex-direction: column;
            top: 50%;
            transform: translateY(-75%);
            .left {
                width: 80vw;
                
                p {
                    margin-left: 0;
                    position: relative;
                    font-size: 25px;
                }
            }
    
            .right {
                width: 80vw;
                
                grid-template-columns: 1fr;
                position: relative;
                
                
    
                .kontakt-item {
                    margin-bottom: 1vw;
    
                    .icon {
                        width: 40px;
                        height: 40px;
    
                        img {
                            width: 20px;
                            height: auto;
    
                        }
                    }
    
                    h1 {
                        margin-top: -33px;
                        margin-left: 65px;
                        color: #FFFFFF;
                        font-weight: 600;
                        font-size: 18px;
                    }
    
                    ul {
                        margin-top: 10px;
    
                        li {
                            font-size: 11px;
    
                        }
                    }
                }
            }
        }
    }
}