#landing-style {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    .landing-elements {
        z-index: 100;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        padding: 0 1%;
        flex-direction: column;
        
        justify-content: space-between;
        width: 70vw;
        height: auto;
        
        h1 {
            font-size: 110px;
            text-align: center;
            font-weight: bold;
            letter-spacing: 5px;
            flex: 1;
            margin: 0px 0%;
            white-space: nowrap;
            span {
                white-space: nowrap;
            }
        }
    
        p {
            position: relative;
            left: 50%;
            transform: translate(-50%);
            width: 600px;
            text-align: center;
            font-size: 26px;

            flex: 1;
            margin: 0px 0%;
            span {
                color: #00A5EC;
                &:nth-child(1) {
                    color: #000000;
                    font-weight: bold;
                }
            }
        }
        
        button {
            position: relative;
            left: 50%;
            transform: translate(-50%);
            width: 200px;
            min-height: 65px;
            border: none;
            border-radius: 50px;
            background-color: #000000;
            color: #ffffff;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;
            letter-spacing: 2px;
            flex: 1;
            margin: 30px 0%;
        }
    
        
        
    }
    
    .background {
        left: 50%;
        transform: translate(-50%);
        width: 1920px;
        position: absolute;
        overflow: hidden;
    }
}

@media screen and (max-width: 1200px) {
    #landing-style {
        .landing-elements {
            width: 90vw;
            
        }
    }
}

@media screen and (max-width: 1000px) {
    #landing-style {
        .landing-elements {
            
            h1 {
                font-size: 80px;
                white-space: initial;
            }
            p {
                font-size: 20px;
            }
        }
    }
}
    
@media screen and (max-width: 900px) {
    #landing-style {
        .landing-elements {
            width: 100vw;
        }
    }
}

@media screen and (max-width: 770px) {
    #landing-style {
        .landing-elements {
            
            h1 {
                font-size: 60px;
            }
            p {
                font-size: 18px;
                width: 440px;
            }
            
        }
    }
}


@media screen and (max-width: 560px) {
    #landing-style {
        .landing-elements {
            padding-left: 25vw;
            
            h1 {
                font-size: 60px;
                text-align: left;
                letter-spacing: 4px;
                width: 250px;
                margin-bottom: 20px;
            }

            p {
                font-size: 18px;
                width: 440px;
                text-align: left;
                width: 70vw;
                left: 0;
                transform: translate(0);
                margin-bottom: 20px;
            }

            button {
                left: 0;
                transform: translate(0);
                min-height: 55px;
                max-height: 55px;
                width: 160px;
                font-size: 16px;
            }
        }
    }
}