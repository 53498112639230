body {
    padding: 0;
    margin: 0;
    font-family: Nunito Sans;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
}


@import './components/navbar';

@import './pages/landing';
@import './pages/onas';
@import './pages/storitve';
@import './pages/kontakt';
@import './pages/eu-projekti';
@import './pages/senzorji';